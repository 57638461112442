/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
// import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Hero from "../IndexSections/Hero.js";

class Landing extends React.Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.state = {
      email: '',
      organization: '',
      comments: '',
    };
    //this.submitToContactAPI = this.submitToContactAPI.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  submitToContactAPI(e) {
    e.preventDefault();
    var organization = this.state.organization;
    var email = this.state.email;
    var message = this.state.comments;
    console.log(this.state.comments);
    var data = {
       organization : organization,
       email : email,
       message : message
     };

    fetch("/api/info", {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((response) => {
      alert("Thanks for reaching out! Your message is on its way!");
      this.setState({
        email: '',
        organization: '',
        comments: '',
      });
    }).catch((error) => {
      alert('Sorry something went wrong and we were unable to send your message. Feel free to email us at <a href="mailto: contact@hichisp.com">contact@hichisp.com</a>');
    });
  }

  render() {
    const { email, organization, comments } = this.state;
    return (
      <>
        <DemoNavbar />
        <main ref="main">
        <Hero />
          <section className="section bg-white">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."

                      src={require("assets/img/theme/girlsplayingbasketball.jpg")}

                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">

                        Building Active Communities by Simplifying Access to Recreation
                      </h4>
                      <p className="lead text-italic text-white">
                      A Chisp kiosk gives you access to all your favorite <strong>equipment and games</strong>

                      </p>
                    </blockquote>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-4">
                      <i className="ni ni-button-power" />
                    </div>
                    <h3>Our Story</h3>
                    <p className="lead">
                      How many times did you forget to bring a ball to the park? 
                      How many times did you just want to try a sport for fun?
                      Why is it necessary to bring your own equipment to recreational spaces?
                    </p>
                    <p>
                      Imagine going to the park, or even to a basketball court, ready to meet new people. You see everyone having fun, tossing around a ball, frisbee, or hopping on the new trend of Pickleball. 
                      You are anxious to join in at any of these games, but realize you are empty handed. How can you join in on the fun without carrying heavy equipment with you?
                      How can you quickly join your buddies without worrying about "the fear of missing out?"
                      Well there is hope...
                    </p>
                    <p>
                      Chisp was founded on the principle that everyone should have the ability to access recreational equipment in their favorite spaces at any time: <strong>Recreation on Demand.</strong>
                    </p>
                    {/* <a
                     className="font-weight-bold text-warning mt-5"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                    /A beautiful UI Kit for impactful websites
                    </a> */}

                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section pb-0 bg-gradient-warning">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-center img-fluid"

                      src={require("assets/img/theme/chisplay-locker.jpg")}

                    />
                  </div>
                </Col>
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3">
                    <div className="pl-4">
                    <img
                      alt="..."
                      className="img-center img-fluid"

                      src={require("assets/img/brand/chisplay_v2_white.png")}

                    />
                      <p className="text-white" align="right">

                        A Sports Vending Machine

                      </p>
                    </div>
                  </div>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">

                            <i className="ni ni-button-play" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">

                            What can you play?
                          </h5>
                          <p>
                            Basketball, Football, Tennis, and so much more!
                          </p>
                          <p class="font-weight-bold"> 
                            Let your community decide
                          </p>
                          {/* <a

                            className="text-success"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </a> */}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-settings-gear-65" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-warning">
                            Features
                          </h5>
                          <h6>Weatherproof</h6>
                          <p>
                            IP65 rated to withstand the harshest weather conditions
                          </p>
                          <h6>Solar Powered</h6>
                          <p>
                            Battery operated, no outlet required
                          </p>
                          <h6>Sanitization</h6>
                          <p>
                            Equipment is sanitized via UV light after every return
                          </p>
                          {/* <a
                            className="text-warning"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </a> */}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Meet the Team</h2>
                  {/* <p className="lead text-muted">According to all Chispers, 
                    these guys know what's up.
                  </p> */}
                </Col>
              </Row>
              <Row>
                <Col className="mb-5 mb-lg-0" lg="2" md="6"></Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/jarret.jpeg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Jarret Ealy</span>
                        <small className="h6 text-muted">Head of Operations</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="warning"
                          href="https://www.linkedin.com/in/jarretealy/"
                          target="_blank"
                        >
                          <i className="fa fa-linkedin" /> 
                        </Button>
                        {/* <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button> */}
{/*                         
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button> */}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/adam.jpeg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Adam Heimowitz</span>
                        <small className="h6 text-muted">Head of Software</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="info"
                          href="https://www.linkedin.com/in/adam-heimowitz-972a1a4b/"
                          target="_blank"
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                        {/* <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                      </Button> */}
                        {/* <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-football" />
                        </Button> */}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/craig.jpeg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Craig Wilkinson</span>
                        <small className="h6 text-muted">Head of Hardware</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="success"
                          href="https://www.linkedin.com/in/craig-wilkinson-b3429078/"
                          target="_blank"
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                        {/* <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                    </Button> */}
                        {/* <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button> */}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6"></Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">How does it work?</h2>
                  <p className="lead text-white">
                    Our Kiosks are very simple to use just follow the steps below!
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="4">
                <div className="pt-4 text-center">
                  <img
                        alt="..."
                        
                        className="img-left img-fluid img-center"
                        src={require("assets/img/theme/vending-machine.png")}
                        style={{ width: "100px" }}
                      />
                    {/*<div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                      <i className="ni ni-settings text-primary" />
                      </div>*/}
                    <h4 className="text-white mt-3">Rent</h4>
                    <p className="text-white mt-3">
                      Rent in person or reserve online!
                    </p>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="pt-4 text-center">
                    <img
                        alt="..."
                        
                        className="img-left img-fluid img-center"
                        src={require("assets/img/theme/american-football.png")}
                        style={{ width: "100px" }}
                      />
                    {/*<div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                      <i className="ni ni-ruler-pencil text-primary" />
                    </div>*/}
                    <h4 className="text-white mt-3">Play</h4>
                    <p className="text-white mt-3">
                      Have fun!
                    </p>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="pt-4 text-center">
                    <img
                          alt="..."
                          
                          className="img-left img-fluid img-center"
                          src={require("assets/img/theme/celebrating.png")}
                          style={{ width: "100px" }}
                        />
                      {/*<div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-atom text-primary" />
                    </div> */}
                      <h4 className="text-white mt-3">Return</h4>
                      <p className="text-white mt-3">
                        Drop it off and we'll clean it for you! 
                      </p>
                  </div>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Would you like Chisplay in your community?</h4>
                      <p className="mt-0">
                        We would love to work with you!
                      </p>
                    <Form className="form" onSubmit={(e) => this.submitToContactAPI(e)}>
          <FormGroup>
            <Input
              type="email"
              name="email"
              id="exampleEmail"
              placeholder="current_email@example.com"
              value={email}
              onChange={(e) => {
                this.handleChange(e);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="organization"
              id="exampleOrg"
              placeholder="Company Name"
              value={organization}
              onChange={(e) => this.handleChange(e)}
            />
          </FormGroup>
          <FormGroup className="mb-4">
            <Input
              type="textarea"
              name="comments"
              id="exampleOrg"
              placeholder="What are you looking to learn?"
              value={comments}
              onChange={(e) => this.handleChange(e)}
            />
          </FormGroup>
          <Button
            block
            className="btn-round"
            color="default"
            size="lg">
                  Submit
          </Button>                      
        </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default Landing;
